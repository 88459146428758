<template>
  <v-app>
    <Layout :title="title" :page-number="page" v-on:paginate="paginate">
      <form v-if="page === 1" class="form" autocomplete @submit.prevent>
        <fieldset v-for="(form, index) in forms" :key="index">
          <legend>
            <small class="font-weight-bold">{{ form.title }}</small>
          </legend>
          <v-row>
            <v-col
              :cols="field.columns"
              v-for="(field, fieldIndex) in form.inputs"
              :key="fieldIndex"
            >
              <!-- text inputs -->
              <label class="w-100" v-if="field.type === 'text'">
                <v-text-field
                  :type="field.type"
                  :name="field.name"
                  :label="field.placeholder"
                  :required="field.required"
                  v-model="field.model"
                  outlined
                ></v-text-field>
              </label>
              <!-- text input -->

              <!-- telephone input  -->
              <label class="w-100" v-if="field.type === 'number'">
                <v-text-field
                  :type="field.type"
                  :name="field.name"
                  :label="field.placeholder"
                  :required="field.required"
                  v-model="field.model"
                  outlined
                ></v-text-field>
              </label>
              <!-- telephone input -->

              <!-- email input -->
              <label class="w-100" v-if="field.type === 'email'">
                <v-text-field
                  :type="field.type"
                  :label="field.placeholder"
                  :name="field.name"
                  :required="field.required"
                  v-model="field.model"
                  outlined
                ></v-text-field>
              </label>
              <!-- end email input -->

              <!-- password input -->
              <label class="w-100" v-if="field.type === 'password'">
                <v-text-field
                  :type="field.type"
                  :name="field.name"
                  :label="field.placeholder"
                  :required="field.required"
                  v-model="field.model"
                  outlined
                ></v-text-field>
              </label>
              <!-- password input -->

              <!-- date input -->
              <label class="w-100" v-if="field.type === 'date'">
                <v-row>
                  <v-col cols="3">
                    <p class="w-100 py-2">{{ field.label }}:</p>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="70"
                      transition="scale-transition"
                      offset-y
                      min-width="300px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          class="mt-0 pt-0"
                          :value="field.model.split('-').reverse().join('-')"
                          placeholder="-Select-"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green darken-4"
                        v-model="field.model"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </label>
              <!-- date input -->

              <!-- radio input -->
              <v-container fluid v-if="field.type === 'radio'" class="py-0">
                <v-radio-group
                  :name="field.name"
                  class="my-0 w-100 py-0"
                  :required="field.required"
                  v-model="field.model"
                  :mandatory="field.required"
                >
                  <v-row>
                    <v-col cols="4">
                      <p>{{ field.label }}</p>
                    </v-col>
                    <v-col
                      v-for="(option, optionIndex) in field.options"
                      :key="optionIndex"
                    >
                      <v-radio
                        color="green darken-4"
                        :label="option"
                        :value="option"
                      ></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <template
                  v-if="field.model === field.options[0] && field.subfield"
                >
                  <v-container v-if="field.subfield.type === 'textarea'">
                    <v-textarea
                      color="green darken-4"
                      :name="field.subfield.name"
                      :label="field.subfield.label"
                      v-model="field.subfield.model"
                      :hint="field.subfield.placeholder"
                    ></v-textarea>
                  </v-container>
                  <v-container
                    fluid
                    v-else-if="field.subfield.type === 'select'"
                  >
                    <v-select
                      :label="field.subfield.label"
                      :items="field.subfield.options"
                      :multiple="field.subfield.multiple"
                      color="green darken-4"
                      item-color="green darken-4"
                      v-model="field.subfield.model"
                      outlined
                    ></v-select>
                    <v-textarea
                      v-if="
                        field.subfield.model === 'Other (Please specify)' &&
                        field.subfield.multiple === false
                      "
                      color="green darken-4"
                      :name="field.subfield.subfield.name"
                      :label="field.subfield.subfield.label"
                      :value="field.subfield.subfield.model"
                      :hint="field.subfield.subfield.placeholder"
                    ></v-textarea>
                  </v-container>
                </template>
              </v-container>
              <!-- radio input -->

              <!-- select input -->
              <v-select
                v-else-if="
                  field.type === 'select' && field.name === 'residence_lga'
                "
                :label="field.label"
                :items="lga_options"
                :readonly="false"
                :aria-readonly="false"
                :multiple="field.multiple"
                color="green darken-4"
                item-color="green darken-4"
                v-model="field.model"
                outlined
              ></v-select>
              <v-select
                v-else-if="
                  field.type === 'select' &&
                  field.name === 'guardian_lga_origin'
                "
                :label="field.label"
                :items="guardian_lga_options"
                color="green darken-4"
                item-color="green darken-4"
                v-model="field.model"
                outlined
              ></v-select>
              <v-select
                v-else-if="
                  field.type === 'select' &&
                  field.name === 'guardian_lga_residence'
                "
                :label="field.label"
                :items="guardian_lga_residence_options"
                color="green darken-4"
                item-color="green darken-4"
                v-model="field.model"
                outlined
              ></v-select>
              <v-select
                v-else-if="field.type === 'select'"
                :label="field.label"
                :items="field.options"
                :multiple="field.multiple"
                color="green darken-4"
                item-color="green darken-4"
                v-model="field.model"
                outlined
              ></v-select>
              <!-- end select input -->
            </v-col>
          </v-row>
        </fieldset>
        <v-row>
          <v-col>
            <v-btn
              medium
              class="text-capitalize"
              @click="paginate(1)"
              color="white--text font-weight-bold green darken-4 mr-4"
              >Proceed</v-btn
            >
            <v-btn
              medium
              class="text-capitalize"
              disabled
              color="black--text font-weight-bold grey darken-6 ml-4"
              >Save for later</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </form>
      <Biometric
        v-else-if="page === 2"
        :data="registrationFormDisplayData"
        v-on:paginate="paginate"
      />
      <Payment v-else :plan="plan" :register="register" />
    </Layout>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Layout from "./components/Layout.vue";
import Biometric from "./components/Biometric.vue";
import Payment from "./components/Payment.vue";
import common from "@/assets/json/forms/common.json";
import registrationMixin from "@/views/mixins/registrationMixin";

export default {
  name: "informal",
  components: {
    Layout,
    Biometric,
    Payment,
  },
  mixins: [registrationMixin],
  data() {
    return {
      page: 1,
      title: "Informal Health Plan",
      plan: "Informal",
      lga_options: [],
      guardian_lga_options: [],
      guardian_lga_residence_options: [],
      menu: false,
      forms: [
        common,
        {
          id: "demographics",
          title: "Demographics",
          inputs: [
            {
              label: "Date of Birth",
              name: "date_of_birth",
              placeholder: "Date of Birth",
              required: true,
              type: "date",
              model: "",
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "Gender",
              name: "sex",
              required: true,
              type: "radio",
              model: "",
              options: ["Male", "Female"],
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "Nationality",
              name: "nationality",
              placeholder: "Nationality",
              required: true,
              type: "text",
              model: "Nigerian",
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "National ID Number (if available)",
              name: "national_id",
              placeholder: "National ID Number",
              required: false,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "State of Origin",
              name: "state",
              placeholder: "State of Origin",
              required: true,
              type: "select",
              multiple: false,
              model: "",
              ref: "state-origin",
              options: [
                "Abia",
                "Adamawa",
                "Anambra",
                "Bauchi",
                "Bayelsa",
                "Benue",
                "Borno",
                "Cross River",
                "Delta",
                "Ebonyi",
                "Edo",
                "Ekiti",
                "Rivers",
                "Enugu",
                "FCT",
                "Gombe",
                "Imo",
                "Jigawa",
                "Kaduna",
                "Kano",
                "Katsina",
                "Kebbi",
                "Kogi",
                "Kwara",
                "Lagos",
                "Nasarawa",
                "Niger",
                "Ogun",
                "Ondo",
                "Osun",
                "Oyo",
                "Plateau",
                "Sokoto",
                "Taraba",
                "Yobe",
                "Zamfara",
              ],
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "Are you homeless?",
              name: "homeless",
              required: true,
              type: "radio",
              options: ["No", "Yes"],
              model: "",
              subfield: {
                label: "Residency Address",
                name: "address",
                placeholder: "Residency Address",
                required: true,
                type: "textarea",
                model: "",
                columns: 12,
                sectionID: "demographics",
              },
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "State of Residence",
              name: "residence_state",
              placeholder: "State of Residence",
              required: true,
              type: "select",
              multiple: false,
              model: "",
              ref: "state-residence",
              options: [
                "Abia",
                "Adamawa",
                "Anambra",
                "Bauchi",
                "Bayelsa",
                "Benue",
                "Borno",
                "Cross River",
                "Delta",
                "Ebonyi",
                "Edo",
                "Ekiti",
                "Rivers",
                "Enugu",
                "FCT",
                "Gombe",
                "Imo",
                "Jigawa",
                "Kaduna",
                "Kano",
                "Katsina",
                "Kebbi",
                "Kogi",
                "Kwara",
                "Lagos",
                "Nasarawa",
                "Niger",
                "Ogun",
                "Ondo",
                "Osun",
                "Oyo",
                "Plateau",
                "Sokoto",
                "Taraba",
                "Yobe",
                "Zamfara",
              ],
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "LGA of Residence",
              name: "residence_lga",
              placeholder: "LGA of Residence",
              required: true,
              type: "select",
              model: "",
              columns: 6,
              sectionID: "demographics",
            },

            {
              label: "Residency Address",
              name: "address",
              placeholder: "Residency Address",
              required: true,
              type: "textarea",
              model: "",
              columns: 12,
              sectionID: "demographics",
            },
            {
              label: "Employment Status",
              name: "employment",
              required: true,
              type: "select",
              multiple: false,
              options: ["Employed", "Unemployed", "Self-Employed"],
              model: "",
              columns: 6,
              sectionID: "demographics",
            },
            {
              label: "Profession/Trade",
              name: "address",
              placeholder: "Profession/Trade",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "demographics",
            },
          ],
        },
        {
          id: "medical-history",
          title: "Medical History",
          inputs: [
            {
              label: "Blood Group",
              name: "blood_group",
              type: "select",
              multiple: false,
              required: true,
              options: ["A+", "A-", "AB+", "AB-", "B+", "B-", "O+", "O-"],
              model: "",
              columns: 6,
              sectionID: "medical-history",
            },
            {
              label: "Genotype",
              name: "genotype",
              type: "select",
              multiple: false,
              required: true,
              options: ["AA", "AS", "SS", "AC", "SC"],
              model: "",
              columns: 6,
              sectionID: "medical-history",
            },
            {
              label: "Any disability?",
              name: "disability_question",
              type: "radio",
              options: ["Yes", "No"],
              model: "",
              required: true,
              subfield: {
                label: "Select Disability Category",
                name: "disability",
                required: true,
                type: "select",
                multiple: true,
                options: [
                  "Visual Impairment",
                  "Hearing Impairment",
                  "Intellectual Impairment",
                  "Physical Impairment",
                  "Other (Please specify)",
                ],
                model: "",
                subfield: {
                  label: "Other Disability (Please Specify)",
                  name: "disability_report",
                  placeholder: "Specify disability here",
                  required: true,
                  type: "textarea",
                  model: "",
                  columns: 12,
                  sectionID: "medical-history",
                },
                columns: 6,
                sectionID: "medical-history",
              },
              columns: 6,
              sectionID: "medical-history",
            },
            {
              label: "Other Disability (Please Specify)",
              name: "disability_report",
              placeholder: "Specify disability here",
              required: true,
              type: "textarea",
              model: "",
              columns: 12,
              sectionID: "medical-history",
            },
            {
              label: "Any Allergies?",
              name: "allergy",
              type: "radio",
              options: ["Yes", "No"],
              model: "",
              subfield: {
                label: "Allergies (Please Specify and separate with comma)",
                name: "allergy_reports",
                placeholder: "Specify allergies here",
                required: true,
                type: "textarea",
                model: "",
                columns: 12,
                sectionID: "medical-history",
              },
              required: true,
              columns: 6,
              sectionID: "medical-history",
            },
            {
              label: "HIV Status",
              name: "hiv_status",
              type: "radio",
              options: ["Positive", "Negative"],
              model: "",
              required: true,
              columns: 6,
              sectionID: "medical-history",
            },
            {
              label: "Pregnancy Status",
              name: "pregnancy_status",
              required: true,
              options: ["Yes", "No"],
              model: "",
              columns: 6,
            },
            {
              label:
                "Have you been diagnosed with a chronic condition? (Yes/No)",
              name: "diagnosis",
              required: true,
              options: ["Yes", "No"],
              model: "",
              columns: 12,
            },
            {
              label: "Diagnosis (Please Specify and separate with comma)",
              name: "diagnosis_reports",
              placeholder: "Specify diagnosis here",
              required: true,
              type: "textarea",
              model: "",
              columns: 12,
              sectionID: "medical-history",
            },
          ],
        },
        {
          id: "guardian-information",
          title: "Guardian Information",
          inputs: [
            {
              label: "First Name *",
              name: "guardian_first_name",
              placeholder: "First Name",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "guardian-information",
            },
            {
              label: "Last Name *",
              name: "guardian_last_name",
              placeholder: "Last Name",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "guardian-information",
            },
            {
              label: "Other Names (Separate with a comma)",
              name: "guardian_other_name",
              placeholder: "Other Names",
              required: false,
              type: "text",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "Relationship with Enrollee",
              name: "guardian_relationship",
              placeholder: "Relationship with Enrollee",
              required: false,
              type: "text",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "Guardian Nationality",
              name: "guardian_nationality",
              placeholder: "Nationality",
              required: false,
              type: "text",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "State of Origin",
              name: "guardian_state_origin",
              placeholder: "State of Origin",
              required: false,
              type: "select",
              multiple: false,
              model: "",
              options: [
                "Abia",
                "Adamawa",
                "Anambra",
                "Bauchi",
                "Bayelsa",
                "Benue",
                "Borno",
                "Cross River",
                "Delta",
                "Ebonyi",
                "Edo",
                "Ekiti",
                "Rivers",
                "Enugu",
                "FCT",
                "Gombe",
                "Imo",
                "Jigawa",
                "Kaduna",
                "Kano",
                "Katsina",
                "Kebbi",
                "Kogi",
                "Kwara",
                "Lagos",
                "Nasarawa",
                "Niger",
                "Ogun",
                "Ondo",
                "Osun",
                "Oyo",
                "Plateau",
                "Sokoto",
                "Taraba",
                "Yobe",
                "Zamfara",
              ],
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "LGA of Origin",
              name: "guardian_lga_origin",
              placeholder: "LGA of Origin",
              required: false,
              type: "select",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "State of Residence",
              name: "guardian_state_residence",
              placeholder: "State of Residence",
              required: false,
              type: "select",
              multiple: false,
              model: "",
              options: [
                "Abia",
                "Adamawa",
                "Anambra",
                "Bauchi",
                "Bayelsa",
                "Benue",
                "Borno",
                "Cross River",
                "Delta",
                "Ebonyi",
                "Edo",
                "Ekiti",
                "Rivers",
                "Enugu",
                "FCT",
                "Gombe",
                "Imo",
                "Jigawa",
                "Kaduna",
                "Kano",
                "Katsina",
                "Kebbi",
                "Kogi",
                "Kwara",
                "Lagos",
                "Nasarawa",
                "Niger",
                "Ogun",
                "Ondo",
                "Osun",
                "Oyo",
                "Plateau",
                "Sokoto",
                "Taraba",
                "Yobe",
                "Zamfara",
              ],
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "LGA of Residence",
              name: "guardian_lga_residence",
              placeholder: "LGA of Residence",
              required: false,
              type: "select",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "Residential Address",
              name: "guardian_address",
              placeholder: "Residential Address",
              required: false,
              type: "text",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
            {
              label: "Contact Information",
              name: "guardian_contact",
              placeholder: "Contact Information e.g phone/email",
              required: false,
              type: "text",
              model: "",
              columns: 12,
              sectionID: "guardian-information",
            },
          ],
        },
        {
          id: "health-facility",
          title: "Health Facility Selection",
          inputs: [
            {
              label: "Preferred Health Facility (First Choice)",
              name: "health_facility_one",
              placeholder: "Preferred Health Facility (First Choice)",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "health-facility",
            },
            {
              label: "Preferred Health Facility LGA",
              name: "health_facility_lga",
              placeholder: "Preferred Health Facility LGA",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "health-facility",
            },

            {
              label: "Preferred Health Facility (Second Choice)",
              name: "health_facility_two",
              placeholder: "Preferred Health Facility (Second Choice)",
              required: true,
              type: "text",
              model: "",
              columns: 6,
              sectionID: "health-facility",
            },
          ],
        },
      ],
    };
  },
  watch: {
    selectedResidenceState: function (current, previous) {
      if (current !== previous && current !== undefined) {
        this.lga_options = this.populateLGA(current);
      }
    },
    selectedGuardianOriginState: function (current, previous) {
      if (current !== previous && current !== undefined) {
        this.guardian_lga_options = this.populateLGA(current);
        this.guardian_lga_options;
      }
    },
    selectedGuardianResidenceState: function (current, previous) {
      if (current !== previous && current !== undefined) {
        this.guardian_lga_residence_options = this.populateLGA(current);
        this.guardian_lga_residence_options;
      }
    },
  },
  methods: {
    ...mapActions({ registerUserPlan: "enrollmentModule/registerUserPlan" }),
  },
  computed: {
    sections() {
      // ====================================
      // gets the section of the form inputs
      // =====================================
      const [
        personalDetailsSection,
        demographicsSection,
        medicalHistorySection,
        guardianInfoSection,
        healthFacilitySection,
      ] = this.forms;

      return {
        personalDetailsSection,
        demographicsSection,
        medicalHistorySection,
        guardianInfoSection,
        healthFacilitySection,
      };
    },
    userDetailInputs() {
      // ==================================
      //  user details inputs
      // ===================================
      const [
        first_name,
        last_name,
        other_name,
        username,
        email,
        phone,
        password,
        confirm_password,
      ] = this.sections.personalDetailsSection.inputs;

      return {
        first_name,
        last_name,
        other_name,
        username,
        email,
        phone,
        password,
        confirm_password,
      };
    },
    userDemographicsInputs() {
      // ==================================
      //  user demographics inputs
      // ===================================
      const [
        date_of_birth,
        gender,
        nationality,
        national_id,
        state,
        homeless,
        residence_state,
        lga,
        address,
      ] = this.sections.demographicsSection.inputs;

      return {
        date_of_birth,
        gender,
        nationality,
        national_id,
        state,
        homeless,
        residence_state,
        lga,
        address,
      };
    },
    userMedicalHistoryInputs() {
      // ==================================
      //  user medical history inputs
      // ===================================
      const [
        blood_group,
        genotype,
        disability,
        other_disabilities,
        allergies,
        hiv_status,
        pregnancy_status,
        diagnosis,
        diagnosis_reports,
      ] = this.sections.medicalHistorySection.inputs;

      return {
        blood_group,
        genotype,
        disability,
        other_disabilities,
        allergies,
        hiv_status,
        pregnancy_status,
        diagnosis,
        diagnosis_reports,
      };
    },
    userGuardianInfoInputs() {
      // ==================================
      //  user guardian information inputs
      // ===================================
      const [
        guardian_first_name,
        guardian_last_name,
        guardian_other_name,
        guardian_relationship,
        guardian_nationality,
        guardian_state_origin,
        guardian_lga_origin,
        guardian_state_residence,
        guardian_lga_residence,
        guardian_address,
        guardian_contact,
      ] = this.sections.guardianInfoSection.inputs;

      return {
        guardian_first_name,
        guardian_last_name,
        guardian_other_name,
        guardian_relationship,
        guardian_nationality,
        guardian_state_origin,
        guardian_lga_origin,
        guardian_state_residence,
        guardian_lga_residence,
        guardian_address,
        guardian_contact,
      };
    },
    healthFacilityInputs() {
      // ==================================
      //  health facility inputs
      // ===================================
      const [
        preferredHealthFacilityLGA,
        preferredHealthFacilityPrimary,
        preferredHealthFacilitySeconday,
      ] = this.sections.healthFacilitySection.inputs;

      return {
        preferredHealthFacilityLGA,
        preferredHealthFacilityPrimary,
        preferredHealthFacilitySeconday,
      };
    },
    registrationFormData() {
      //====================================================================
      const {
        first_name,
        last_name,
        other_name,
        username,
        email,
        phone,
        password,
        confirm_password,
      } = this.userDetailInputs;

      const {
        date_of_birth,
        gender,
        nationality,
        national_id,
        state,
        residence_state,
        lga,
        address,
      } = this.userDemographicsInputs;

      const {
        blood_group,
        genotype,
        disability,
        other_disabilities,
        allergies,
        hiv_status,
        pregnancy_status,
        diagnosis,
        diagnosis_reports,
      } = this.userMedicalHistoryInputs;

      const {
        guardian_first_name,
        guardian_last_name,
        guardian_other_name,
        guardian_relationship,
        guardian_nationality,
        guardian_state_origin,
        guardian_lga_origin,
        guardian_state_residence,
        guardian_lga_residence,
        guardian_address,
        guardian_contact,
      } = this.userGuardianInfoInputs;

      const {
        preferredHealthFacilityLGA,
        preferredHealthFacilityPrimary,
        preferredHealthFacilitySeconday,
      } = this.healthFacilityInputs;
      //====================================================================
      return {
        // user details
        first_name: `${first_name.model}`,
        last_name: `${last_name.model}`,
        other_name: `${other_name.model}`,
        username: `${username.model}`,
        email: `${email.model}`,
        phone: `${phone.model}`,
        password: `${password.model}`,
        confirm_password: `${confirm_password.model}`,
        // demographics
        date_of_birth: `${date_of_birth.model}`,
        gender: `${gender.model}`,
        nationality: `${nationality.model}`,
        national_id: `${national_id.model}`,
        state: `${state.model}`,
        residence_state: `${residence_state.model}`,
        lga: `${lga.model}`,
        address: `${address.model}`,
        // mdeical history
        blood_group: `${blood_group.model}`,
        genotype: `${genotype.model}`,
        disabilities: `${disability.model} ${other_disabilities.model}`,
        allergies: `${allergies.model}`,
        hiv_status: `${hiv_status.model}`,
        pregnancy_status: `${pregnancy_status.model}`,
        diagnosis: `${diagnosis.model} ${diagnosis_reports.model}`,
        // guardian details
        guardian_first_name: `${guardian_first_name.model}`,
        guardian_last_name: `${guardian_last_name.model}`,
        guardian_other_name: `${guardian_other_name.model}`,
        guardian_relationship: `${guardian_relationship.model}`,
        guardian_nationality: `${guardian_nationality.model}`,
        guardian_state_origin: `${guardian_state_origin.model}`,
        guardian_lga_origin: `${guardian_lga_origin.model}`,
        guardian_state_residence: `${guardian_state_residence.model}`,
        guardian_lga_residence: `${guardian_lga_residence.model}`,
        guardian_address: `${guardian_address.model}`,
        guardian_contact: `${guardian_contact.model}`,
        // health facility
        primary_hcp: `${preferredHealthFacilityPrimary.model}`,
        primary_hcp_lga: `${preferredHealthFacilityLGA.model}`,
        secondary_hcp: `${preferredHealthFacilitySeconday.model}`,
        plan_name: this.plan,
      };
    },
    registrationFormDisplayData() {
      const {
        // user details
        nationality,
        national_id,
        first_name,
        last_name,
        other_name,
        date_of_birth,
        gender,
        phone,
        email,
        address,
        lga,
        state,
        residence_state,
        // guardian details
        guardian_first_name,
        guardian_last_name,
        guardian_other_name,
        guardian_relationship,
        guardian_contact,
        guardian_address,
        guardian_state_origin,
        guardian_lga_origin,
        // health facility
        primary_hcp,
        primary_hcp_lga,
        secondary_hcp,
      } = this.registrationFormData;

      return {
        "EMP No": `${national_id}`,
        Nationality: `${nationality}`,
        "Full Name": `${first_name} ${other_name} ${last_name}`,
        "Date of Birth": `${date_of_birth}`,
        Ministry: `-`,
        "Spouse Name": `-`,
        "No of Children": `-`,
        Phone: `${phone}`,
        Email: `${email}`,
        Address: `${address}`,
        Town: `${lga}, ${residence_state}`,
        Sex: `${gender}`,
        LGA: `${lga}`,
        "Dependants/Children & Spouse": `-`,
        "State of Origin": `${state}`,
        "Next of Kin Name": `${guardian_first_name} ${guardian_other_name}  ${guardian_last_name}`,
        "Next of Kin Phone/Email": `${guardian_contact}`,
        "Next of Kin Address": `${guardian_address}`,
        "Next of Kin LGA": `${guardian_lga_origin}`,
        "Next of Kin State": `${guardian_state_origin}`,
        Relationship: `${guardian_relationship}`,
        "Primary HCP": `${primary_hcp || "Nil"}`,
        "Primary HCP LGA": `${primary_hcp_lga || "Nil"}`,
        "Secondary HCP": `${secondary_hcp || "Nil"}`,
        // "NHIS Enrollee ID": `-`,
        "Choosen Health Plan": `${this.plan}`,
      };
    },
    selectedResidenceState() {
      return this.userDemographicsInputs.residence_state.model;
    },
    selectedGuardianOriginState() {
      return this.userGuardianInfoInputs.guardian_state_origin.model;
    },
    selectedGuardianResidenceState() {
      return this.userGuardianInfoInputs.guardian_state_residence.model;
    },
  },
};
</script>

<style scoped>
#hero {
  background: url("~@/assets/images/svg/form-background.svg") no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: 10px;
}
/* *::-webkit-scrollbar {
	display: none;
} */
</style>
